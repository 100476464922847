import { styled } from 'react-jss'

export const Container = styled('div')({
  marginInline: '5%',
  display: 'flex',
  gap: '4rem',
})

export const LargeContainer = styled(Container)({
  justifyContent: 'center',
  marginBottom: '2rem',
})

export const SmallContainer = styled(Container)({
  flexDirection: 'column',
})
