import AwsCloudPractitionerBadge from './images/aws-cloud-quest-cloud-practitioner.png'
import AwsSolutionsArchitectBadge from './images/aws-cloud-quest-solutions-architect.png'

const AWS_CLOUD_PRACTITIONER = 'aws-cloud-practitioner'
const AWS_SOLUTIONS_ARCHITECT = 'aws-solutions-architect'

export const skillMap = {
	[AWS_CLOUD_PRACTITIONER]: {
		alt: 'Cloud Practioner Icon',
		src: AwsCloudPractitionerBadge
	},
	[AWS_SOLUTIONS_ARCHITECT]: {
		alt: 'Solutions Architect Icon',
		src: AwsSolutionsArchitectBadge
	}
}
