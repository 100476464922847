import { createUseStyles } from 'react-jss'
import { useMediaQuery } from 'react-responsive'

const useStyles = createUseStyles(({ primary, secondary }) => ({
  bar: ({ isMobile }) => ({
    display: 'flex',
    justifyContent: isMobile ? 'center' : 'end',
    height: '4rem',
    width: '100%',
  }),
  menu: ({ isMobile }) => ({
    height: '100%',
    width: '15rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...(!isMobile && { marginRight: '3em' }),
  }),
  item: {
    cursor: 'pointer',
    color: secondary,
    '&:hover': {
      color: primary,
    },
  },
}))

export const NavBar = ({ aboutRef, contactRef, experienceRef }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const classes = useStyles({ isMobile })

  return (
    <div className={classes.bar}>
      <div className={classes.menu}>
        <div
          className={classes.item}
          onClick={() => window.scrollTo(0, aboutRef.current.offsetTop)}
        >
          about
        </div>
        <div
          className={classes.item}
          onClick={() => window.scrollTo(0, experienceRef.current.offsetTop)}
        >
          experience
        </div>
        <div
          className={classes.item}
          onClick={() => window.scrollTo(0, contactRef.current.offsetTop)}
        >
          contact
        </div>
      </div>
    </div>
  )
}
