import { styled } from 'react-jss'

import { Image } from '../image'

import {skillMap} from './skill-map'

const Container = styled(`div`)({
  display: 'flex',
  justifyContent: 'center',
	flexWrap: 'wrap',
	gap: 24
})

const HEIGHT = '150px'

export const Skills = () => {
  return (
    <Container>
			{Object.entries(skillMap).map(([key, {alt, src}])=>(
				<Image key={key} src={src} height={HEIGHT} alt={alt} />
			))}
    </Container>
  )
}
