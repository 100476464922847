import { AiOutlineCopyrightCircle } from 'react-icons/ai'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(({ primary }) => ({
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: '1rem',
    fontSize: '0.8rem',
    color: primary,
    gap: '0.5rem',
  },
}))

export const Footer = () => {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <AiOutlineCopyrightCircle />
      Jeremy Luzzi Design and Development 2022 - 2023
    </footer>
  )
}
