import { createUseStyles } from 'react-jss'
import { useMediaQuery } from 'react-responsive'

import { Brainimation } from './brainimation'
import logo from './logo.png'

const useStyles = createUseStyles({
  header: ({ isMobile }) => ({
    width: isMobile ? '100%' : 'max(60%, 600px)',
    aspectRatio: 2 / 1,
  }),
  layout: {
    height: '70vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  lightbulb: {
    marginTop: '3rem',
    textAlign: 'center',
  },
})

export const Header = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const classes = useStyles({ isMobile })

  return (
    <div className={classes.layout}>
      <img className={classes.header} src={logo} alt='Jeremy Luzzi Logo' />
      <Brainimation />
    </div>
  )
}
