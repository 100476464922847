import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(({ primary, secondary }) => ({
  link: {
    color: secondary,
    '&:hover': {
      color: primary,
    },
  },
}))

export const Link = ({ href, children }) => {
  const classes = useStyles()

  return (
    <a href={href} className={classes.link}>
      {children}
    </a>
  )
}
