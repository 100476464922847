import {
  AiOutlineGithub,
  AiOutlineLinkedin,
  AiOutlineMail,
} from 'react-icons/ai'
import { createUseStyles } from 'react-jss'

import { Link } from './link'

const useStyles = createUseStyles({
  layout: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
  },
})

export const Contact = () => {
  const classes = useStyles()

  return (
    <div className={classes.layout}>
      <Link href='mailto:jeremyluzzidev@gmail.com'>
        <AiOutlineMail size='36px' />
      </Link>

      <Link href='https://github.com/JLuzz'>
        <AiOutlineGithub className={classes.link} size='36px' />
      </Link>

      <Link href='https://www.linkedin.com/in/jeremy-luzzi-00b986a1/'>
        <AiOutlineLinkedin className={classes.link} size='36px' />
      </Link>
    </div>
  )
}
