import { createUseStyles } from 'react-jss'

import { Link } from '../link'

const useStyles = createUseStyles({
  description: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  subtitle: {
    color: 'whitesmoke',
    fontSize: 12,
  },
  title: {
    margin: 0,
  },
  listContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  list: {
    textAlign: 'start',
    listStyleType: 'circle',
  },
})
export const Details = ({ className, experience }) => {
  const classes = useStyles()

  const { name, href, yearsActive, description, bulletPoints } = experience

  return (
    <div className={className}>
      <div>
        <h3 className={classes.title}>
          <Link href={href}>{name}</Link>
        </h3>
        <div
          className={classes.subtitle}
        >{`${yearsActive.start} - ${yearsActive.end}`}</div>
        <div className={classes.description}>{description}</div>
      </div>
      <div className={classes.listContainer}>
        <ul className={classes.list}>
          {bulletPoints.map((bp, i) => (
            <li key={i}>{bp}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}
