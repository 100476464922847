import { styled } from 'react-jss'

import { Image } from '../image'

import { Details } from './details'

const Container = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 300px))',
  alignItems: 'center',
  justifyItems: 'center',
  justifyContent: 'center',
  gap: '2rem',
})

export const ListItem = ({ experience }) => {
  const { icon, name } = experience

  return (
    <Container>
        <Image src={icon} alt={`${name} Icon`} />

      <Details experience={experience} />
    </Container>
  )
}
