import { createUseStyles } from 'react-jss'
import { forwardRef } from 'react'

const useStyles = createUseStyles(({ primary }) => ({
  title: {
    fontFamily: 'Flood Std',
    fontSize: '2rem',
    color: 'black',
    textShadow: `2px 2px 5px ${primary}`,
  },
}))

export const Section = forwardRef(({ children, className, title }, ref) => {
  const classes = useStyles()

  return (
    <div className={className} ref={ref}>
      {title && <h2 className={classes.title}>{title}</h2>}
      {children}
    </div>
  )
})
