import { createUseStyles } from 'react-jss'
import { useMediaQuery } from 'react-responsive'
import { useState } from 'react'

import { Image } from '../image'

import { AMBYINT, ATHENNIAN, CURVE_DENTAL, experiences } from './experiences'
import { Details } from './details'
import { LargeContainer, SmallContainer } from './container'
import { ListItem } from './list-item'

const useStyles = createUseStyles({
  '@keyframes fade-in': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  animated: {
    animation: '$fade-in 1s ease-in-out',
    textAlign: 'center',
    borderRadius: '5px',
  },
})

const orderedExperiences = [ATHENNIAN, CURVE_DENTAL, AMBYINT]

export const Experience = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const classes = useStyles()
  const [selected, setSelected] = useState(null)

  if (isMobile)
    return (
      <SmallContainer>
        {orderedExperiences.map((experience) => (
          <ListItem experience={experiences[experience]} key={experience} />
        ))}
      </SmallContainer>
    )

  return (
    <>
      <LargeContainer>
        {orderedExperiences.map((experience) => {
          const { name, icon } = experiences[experience]

          return (
              <Image
                src={icon}
                alt={`${name} Icon`}
                onMouseEnter={() => setSelected(experience)}
                isSelected={selected === experience}
              />
          )
        })}
      </LargeContainer>
      {selected !== null && (
        <Details
          className={classes.animated}
          experience={experiences[selected]}
        />
      )}
    </>
  )
}
