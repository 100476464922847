import AmbyintIcon from './images/ambyint-logo.png'
import AthennianIcon from './images/athennian-instagram.jpg'
import CurveDentalIcon from './images/curve-dental-logo.jpg'

export const AMBYINT = 'ambyint'
export const ATHENNIAN = 'athennian'
export const CURVE_DENTAL = 'curve-dental'

export const experiences = {
  [AMBYINT]: {
    bulletPoints: [
      'React optimization',
      'Event sourcing',
      'Domain driven design',
      'Microservices architecture',
    ],
    description: 'AI-driven, artificial lift optimization solution',
    href: 'https://www.ambyint.com/',
    icon: AmbyintIcon,
    name: 'Ambyint',
    yearsActive: {
      start: 'MAR 2020',
      end: 'JUN 2022',
    },
  },
  [CURVE_DENTAL]: {
    bulletPoints: [
      'Develop components using Angular',
      'Implement audit system with AWS lamdba and S3',
      'Develop HIPPA compliant software',
    ],
    description: 'Dental practice management software',
    href: 'https://www.curvedental.com/',
    icon: CurveDentalIcon,
    name: 'Curve Dental',
    yearsActive: {
      start: 'DEC 2018',
      end: 'MAR 2020',
    },
  },
  [ATHENNIAN]: {
    bulletPoints: [
      'Created proprietary e-sign solution',
      'Developer training',
      'Developer managment',
    ],
    description: 'Digital legal entity management',
    href: 'https://athennian.com/',
    icon: AthennianIcon,
    name: 'Athennian',
    yearsActive: {
      start: 'FEB 2017',
      end: 'OCT 2018',
    },
  },
}
