import { createUseStyles } from 'react-jss'
import { useMediaQuery } from 'react-responsive'

import jeremy from './jeremy-luzzi.png'

const useStyles = createUseStyles(({ primary }) => ({
  layout: ({ isMobile }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...(isMobile && { flexWrap: 'wrap' }),
    gap: '3rem',
    margin: 'auto 5%',
  }),
  image: {
    height: '400px',
    width: 'auto',
    boxShadow: `2px 2px 10px ${primary}`,
  },
}))

export const About = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const classes = useStyles({ isMobile })

  return (
    <div className={classes.layout}>
      <img className={classes.image} src={jeremy} alt='Jeremy Luzzi' />
      <p>
				A versatile Canadian developer, designer, and gamer with a passion for creating delightful experiences and fostering positive, collaborative environments.
				<br/>
				<br/>
				With my expertise in solutions architecture, I excel at designing innovative solutions that solve complex real-world challenges. I have a proven track record of successful deliveries that exceed expectations.
        <br />
        <br />
        When I am away from the computer, you can find me spending time in the
        mountains with my girlfriend and dog.
      </p>
    </div>
  )
}
