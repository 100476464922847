import { ThemeProvider, styled } from 'react-jss'
import { useRef } from 'react'

import {
  About,
  Contact,
  Experience,
  Footer,
  Header,
  NavBar,
  Section,
  Skills,
} from './components'
import { theme } from './theme'

const Body = styled('div')({
  color: 'white',
  marginInline: 'auto',
  width: '80%',
  display: 'grid',
  gap: '5rem',
  marginTop: '5rem',
  marginBottom: '2rem',
})

const Container = styled('div')(({ theme }) => ({
  background: theme.background,
  height: '100%',
}))

export const App = () => {
  const aboutRef = useRef()
  const experienceRef = useRef()
  const contactRef = useRef()
  const skillsRef = useRef()

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <NavBar
          aboutRef={aboutRef}
          experienceRef={experienceRef}
          contactRef={contactRef}
        />

        <Body>
          <Section>
            <Header />
          </Section>

          <Section title='About' ref={aboutRef}>
            <About />
          </Section>

          <Section title='Experience' ref={experienceRef}>
            <Experience />
          </Section>

          <Section title='Skills' ref={skillsRef}>
            <Skills />
          </Section>

          <Section title='Contact' ref={contactRef}>
            <Contact />
          </Section>
        </Body>

        <Footer />
      </Container>
    </ThemeProvider>
  )
}

export default App
